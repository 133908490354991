* {
    transition: all 0.3s ease-out;
  }
  
  html, body {
    height: 100%;
    font-family: "Nunito", sans-serif;
    font-size: 16px;
  }
  
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  
  h3 {
    color: #262626;
    font-size: 17px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 4px;
  }
  
  p {
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    color: #666666;
  }
  
  p.small {
    font-size: 14px;
  }
  
  .go-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    top: 0;
    right: 0;
    background-color: #00838d;
    border-radius: 0 4px 0 32px;
  }
  
  .go-arrow {
    margin-top: -4px;
    margin-right: -4px;
    color: white;
    font-family: courier, sans;
  }
  
  .card1 {
    display: block;
    position: relative;
    max-width: 262px;
    background-color: #f2f8f9;
    border-radius: 4px;
    padding: 32px 24px;
    margin: 12px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
  }
  
  .card1:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #00838d;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
  }
  
  .card1:hover:before {
    transform: scale(21);
  }
  
  .card1:hover p {
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);
  }
  
  .card1:hover h3 {
    transition: all 0.3s ease-out;
    color: #ffffff;
  }
  
  .card2 {
    display: block;
    top: 0px;
    position: relative;
    max-width: 262px;
    background-color: #f2f8f9;
    border-radius: 4px;
    padding: 32px 24px;
    margin: 12px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    border: 1px solid #f2f8f9;
  }
  
  .card2:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #cccccc;
    background-color: white;
  }
  
  .card2:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #246499;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(2);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
  }
  
  .card2:hover:before {
    transform: scale(2.15);
  }
  
  .card3 {
    display: block;
    top: 0px;
    position: relative;
    max-width: 262px;
    background-color: #f2f8f9;
    border-radius: 4px;
    padding: 32px 24px;
    margin: 12px;
    text-decoration: none;
    overflow: hidden;
    border: 1px solid #f2f8f9;
  }
  
  .card3 .go-corner {
    opacity: 0.7;
  }
  
  .card3:hover {
    border: 1px solid #246499;
    box-shadow: 0px 0px 999px 999px rgba(255, 255, 255, 0.5);
    z-index: 500;
  }
  
  .card3:hover p {
    color: #246499;
  }
  
  .card3:hover .go-corner {
    transition: opactiy 0.3s linear;
    opacity: 1;
  }
  
  .card4 {
    display: block;
    top: 0px;
    position: relative;
    max-width: 262px;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 32px 24px;
    margin: 12px;
    text-decoration: none;
    overflow: hidden;
    border: 1px solid #cccccc;
  }
  
  .card4 .go-corner {
    background-color: #246499;
    height: 100%;
    width: 16px;
    padding-right: 9px;
    border-radius: 0;
    transform: skew(6deg);
    margin-right: -36px;
    align-items: start;
    background-image: linear-gradient(-45deg, #246499 1%, #19850b 100%);
  }
  
  .card4 .go-arrow {
    transform: skew(-6deg);
    margin-left: -2px;
    margin-top: 9px;
    opacity: 0;
  }
  
  .card4:hover {
    border: 1px solid #174981;
  }
  
  .card4 h3 {
    margin-top: 8px;
  }
  
  .card4:hover .go-corner {
    margin-right: -12px;
  }
  
  .card4:hover .go-arrow {
    opacity: 1;
  }
  
  .article-list {
    color: #313437;
    background-color: #fff;
  }
  
  .article-list p {
    color: #7d8285;
  }
  
  .article-list h2 {
    font-weight: bold;
    margin-bottom: 40px;
    padding-top: 40px;
    color: inherit;
  }
  
  @media (max-width:767px) {
    .article-list h2 {
      margin-bottom: 25px;
      padding-top: 25px;
      font-size: 24px;
    }
  }
  
  .article-list .intro {
    font-size: 16px;
    max-width: 500px;
    margin: 0 auto;
  }
  
  .article-list .intro p {
    margin-bottom: 0;
  }
  
  .article-list .articles {
    padding-bottom: 40px;
  }
  
  .article-list .item {
    padding-top: 50px;
    min-height: 425px;
    text-align: center;
  }
  
  .article-list .item .name {
    font-weight: bold;
    font-size: 16px;
    margin-top: 20px;
    color: inherit;
  }
  
  .article-list .item .description {
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 0;
  }
  
  .article-list .item .action {
    font-size: 24px;
    width: 24px;
    margin: 22px auto 0;
    line-height: 1;
    display: block;
    color: #4f86c3;
    opacity: 0.85;
    transition: opacity 0.2s;
    text-decoration: none;
  }
  
  .article-list .item .action:hover {
    opacity: 1;
  }
  
 
  .border-pretty {
    border: 1px solid #ffffff;
    font-size: large;
  }
  
  .border-pretty:hover {
    border: 1px solid darkorange;
    background-color: darkorange;
  }
  
  .btn-link:hover {
    color: #070707;
    text-decoration: none;
  }
  
  .btn.focus, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0,123,255,0);
  }
  
  .btn-link {
    font-weight: 400;
    color: #ffffff;
    text-decoration: none !important;
  }
  
  .example-box {
    background-image: linear-gradient(to bottom right, #13338a, #046e75a6);
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-size: cover;
    color: white;
    font-family: sans-serif;
    font-weight: 200;
    z-index: 1;
  }
  
  .example-box * {
    z-index: 2;
  }
  
  h1 {
    font-family: Montserrat, sans-serif;
    color: white;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  
  .background-shapes {
    content: "";
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 5076px;
    background-size: 100%;
    animation: 120s infiniteScroll linear infinite;
    background-repeat-x: repeat;
    background-image: url(../img/startup.svg);
  }
  
  @keyframes infiniteScroll {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    100% {
      -webkit-transform: translate3d(0, -1692px, 0);
      transform: translate3d(0, -1692px, 0);
    }
  }
  
  .rdmain{
    background-position: center center;
    background-image: url(../img/major.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
  }
  
