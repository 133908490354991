/* -----------
Diagnal sections
---------- */

#diagonal {
    width: 100%;
    background-color: rgba(128, 185, 46, 0.7);
    background: teal;
    color: rgba(128, 185, 46, 0.7);
    transform: rotate(2deg) skew(2deg);
    -webkit-transform: rotate(2deg) skew(2deg);
    -moz-transform: rotate(2deg) skew(2deg);
    -o-transform: rotate(2deg) skew(2deg);
    -ms-transform: rotate(2deg) skew(2deg);
  }
  
  #diagonal .container {
    padding: 0 100px 100px 100px;
  }
  
  #diagonal-text {
    color: #fff;
    padding: 35px 0 15px;
    transform: rotate(-2deg) skew(-2deg);
    -webkit-transform: rotate(-2deg) skew(-2deg);
    -moz-transform: rotate(-2deg) skew(-2deg);
    -o-transform: rotate(-2deg) skew(-2deg);
    -ms-transform: rotate(-2deg) skew(-2deg);
    margin-bottom: -20px;
    padding-bottom: 50px;
  }
  

  .box {
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 5px #555;
  }
  
  .box img {
    width: 100%;
    height: auto;
  }
  
  .box .box-heading {
    width: 90%;
    position: absolute;
    bottom: 25px;
    left: 5%;
    background: #01609056;
    padding: 10px 60px 10px 25px;
    color: #fff;
    transition: all 0.3s ease 0s;
  }
  
  .box:hover .box-heading {
    left: 100%;
  }
  
  .box .box-heading:after {
    content: "\f105";
    font-family: fontawesome;
    width: 40px;
    height: 40px;
    line-height: 40px;
    position: absolute;
    top: 13px;
    right: 11px;
    background: #333;
    font-size: 30px;
    color: #fff;
    text-align: center;
  }
  
  .box .title {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }
  
  .box .post {
    display: block;
    font-size: 14px;
    color: #fff;
    text-transform: capitalize;
    margin-top: 5px;
  }
  
  .box .boxContent {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: -100%;
    padding: 30px 35px;
    background: #01609056;
    transition: all 0.3s ease 0s;
  }
  
  .box:hover .boxContent {
    left: 0;
  }
  
  .box .description {
    font-size: 14px;
    color: #fff;
    line-height: 25px;
  }
  
  .box .read {
    display: inline-block;
    padding: 10px 20px;
    background: #333;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    margin-top: 20px;
    transition: all 0.3s ease 0s;
  }
  
  .box .read:hover {
    text-decoration: none;
    background: #333;
    color: #016090;
  }
  
  .box .read i {
    font-size: 20px;
    font-weight: 700;
    margin-left: 10px;
  }
  
  @media only screen and (max-width: 990px) {
    .box {
      margin-bottom: 20px;
    }
  }
  
  @media only screen and (max-width: 479px) {
    .box .boxContent {
      padding: 10px;
    }
  }
  
  @media only screen and (max-width: 479px) {
    .box .read {
      margin-top: 10px;
    }
  }
  
  /* ===================Slider ====================*/

  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  
  .animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
  
  @keyframes bounce {
  }
  
  .bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }
  
  @keyframes flash {
  }
  
  .flash {
    -webkit-animation-name: flash;
    animation-name: flash;
  }
  
  @keyframes pulse {
  }
  
  .pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
  }
  
  @keyframes rubberBand {
  }
  
  .rubberBand {
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand;
  }
  
  @keyframes shake {
  }
  
  .shake {
    -webkit-animation-name: shake;
    animation-name: shake;
  }
  
  @keyframes headShake {
  }
  
  .headShake {
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-name: headShake;
    animation-name: headShake;
  }
  
  @keyframes swing {
  }
  
  .swing {
    -webkit-transform-origin: top center;
    transform-origin: top center;
    -webkit-animation-name: swing;
    animation-name: swing;
  }
  
  @keyframes tada {
  }
  
  .tada {
    -webkit-animation-name: tada;
    animation-name: tada;
  }
  
  @keyframes wobble {
  }
  
  .wobble {
    -webkit-animation-name: wobble;
    animation-name: wobble;
  }
  
  @keyframes jello {
  }
  
  .jello {
    -webkit-animation-name: jello;
    animation-name: jello;
    -webkit-transform-origin: center;
    transform-origin: center;
  }
  
  @keyframes bounceIn {
  }
  
  .bounceIn {
    -webkit-animation-duration: .75s;
    animation-duration: .75s;
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn;
  }
  
  .bounceOut, .flipOutX {
    -webkit-animation-duration: .75s;
    animation-duration: .75s;
  }
  
  @keyframes bounceInDown {
  }
  
  .bounceInDown {
    -webkit-animation-name: bounceInDown;
    animation-name: bounceInDown;
  }
  
  @keyframes bounceInLeft {
  }
  
  .bounceInLeft {
    -webkit-animation-name: bounceInLeft;
    animation-name: bounceInLeft;
  }
  
  @keyframes bounceInRight {
  }
  
  .bounceInRight {
    -webkit-animation-name: bounceInRight;
    animation-name: bounceInRight;
  }
  
  @keyframes bounceInUp {
  }
  
  .bounceInUp {
    -webkit-animation-name: bounceInUp;
    animation-name: bounceInUp;
  }
  
  @keyframes bounceOut {
  }
  
  .bounceOut {
    -webkit-animation-name: bounceOut;
    animation-name: bounceOut;
  }
  
  @keyframes bounceOutDown {
  }
  
  .bounceOutDown {
    -webkit-animation-name: bounceOutDown;
    animation-name: bounceOutDown;
  }
  
  @keyframes bounceOutLeft {
  }
  
  .bounceOutLeft {
    -webkit-animation-name: bounceOutLeft;
    animation-name: bounceOutLeft;
  }
  
  @keyframes bounceOutRight {
  }
  
  .bounceOutRight {
    -webkit-animation-name: bounceOutRight;
    animation-name: bounceOutRight;
  }
  
  @keyframes bounceOutUp {
  }
  
  .bounceOutUp {
    -webkit-animation-name: bounceOutUp;
    animation-name: bounceOutUp;
  }
  
  @keyframes fadeIn {
  }
  
  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }
  
  @keyframes fadeInDown {
  }
  
  .fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
  }
  
  @keyframes fadeInDownBig {
  }
  
  .fadeInDownBig {
    -webkit-animation-name: fadeInDownBig;
    animation-name: fadeInDownBig;
  }
  
  @keyframes fadeInLeft {
  }
  
  .fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
  }
  
  @keyframes fadeInLeftBig {
  }
  
  .fadeInLeftBig {
    -webkit-animation-name: fadeInLeftBig;
    animation-name: fadeInLeftBig;
  }
  
  @keyframes fadeInRight {
  }
  
  .fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
  }
  
  @keyframes fadeInRightBig {
  }
  
  .fadeInRightBig {
    -webkit-animation-name: fadeInRightBig;
    animation-name: fadeInRightBig;
  }
  
  @keyframes fadeInUp {
  }
  
  .fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
  }
  
  @keyframes fadeInUpBig {
  }
  
  .fadeInUpBig {
    -webkit-animation-name: fadeInUpBig;
    animation-name: fadeInUpBig;
  }
  
  @keyframes fadeOut {
  }
  
  .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
  }
  
  @keyframes fadeOutDown {
  }
  
  .fadeOutDown {
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown;
  }
  
  @keyframes fadeOutDownBig {
  }
  
  .fadeOutDownBig {
    -webkit-animation-name: fadeOutDownBig;
    animation-name: fadeOutDownBig;
  }
  
  @keyframes fadeOutLeft {
  }
  
  .fadeOutLeft {
    -webkit-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft;
  }
  
  @keyframes fadeOutLeftBig {
  }
  
  .fadeOutLeftBig {
    -webkit-animation-name: fadeOutLeftBig;
    animation-name: fadeOutLeftBig;
  }
  
  @keyframes fadeOutRight {
  }
  
  .fadeOutRight {
    -webkit-animation-name: fadeOutRight;
    animation-name: fadeOutRight;
  }
  
  @keyframes fadeOutRightBig {
  }
  
  .fadeOutRightBig {
    -webkit-animation-name: fadeOutRightBig;
    animation-name: fadeOutRightBig;
  }
  
  @keyframes fadeOutUp {
  }
  
  .fadeOutUp {
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp;
  }
  
  @keyframes fadeOutUpBig {
  }
  
  .fadeOutUpBig {
    -webkit-animation-name: fadeOutUpBig;
    animation-name: fadeOutUpBig;
  }
  
  @keyframes flip {
  }
  
  .animated.flip {
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    -webkit-animation-name: flip;
    animation-name: flip;
  }
  
  @keyframes flipInX {
  }
  
  .flipInX {
    -webkit-backface-visibility: visible!important;
    backface-visibility: visible!important;
    -webkit-animation-name: flipInX;
    animation-name: flipInX;
  }
  
  .flipInY, .flipOutX {
    -webkit-backface-visibility: visible!important;
    backface-visibility: visible!important;
  }
  
  @keyframes flipInY {
  }
  
  .flipInY {
    -webkit-animation-name: flipInY;
    animation-name: flipInY;
  }
  
  @keyframes flipOutX {
  }
  
  .flipOutX {
    -webkit-animation-name: flipOutX;
    animation-name: flipOutX;
  }
  
  @keyframes flipOutY {
  }
  
  .flipOutY {
    -webkit-animation-duration: .75s;
    animation-duration: .75s;
    -webkit-backface-visibility: visible!important;
    backface-visibility: visible!important;
    -webkit-animation-name: flipOutY;
    animation-name: flipOutY;
  }
  
  @keyframes lightSpeedIn {
  }
  
  .lightSpeedIn {
    -webkit-animation-name: lightSpeedIn;
    animation-name: lightSpeedIn;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  
  @keyframes lightSpeedOut {
  }
  
  .lightSpeedOut {
    -webkit-animation-name: lightSpeedOut;
    animation-name: lightSpeedOut;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  
  @keyframes rotateIn {
  }
  
  .rotateIn {
    -webkit-animation-name: rotateIn;
    animation-name: rotateIn;
  }
  
  @keyframes rotateInDownLeft {
  }
  
  .rotateInDownLeft {
    -webkit-animation-name: rotateInDownLeft;
    animation-name: rotateInDownLeft;
  }
  
  @keyframes rotateInDownRight {
  }
  
  .rotateInDownRight {
    -webkit-animation-name: rotateInDownRight;
    animation-name: rotateInDownRight;
  }
  
  @keyframes rotateInUpLeft {
  }
  
  .rotateInUpLeft {
    -webkit-animation-name: rotateInUpLeft;
    animation-name: rotateInUpLeft;
  }
  
  @keyframes rotateInUpRight {
  }
  
  .rotateInUpRight {
    -webkit-animation-name: rotateInUpRight;
    animation-name: rotateInUpRight;
  }
  
  @keyframes rotateOut {
  }
  
  .rotateOut {
    -webkit-animation-name: rotateOut;
    animation-name: rotateOut;
  }
  
  @keyframes rotateOutDownLeft {
  }
  
  .rotateOutDownLeft {
    -webkit-animation-name: rotateOutDownLeft;
    animation-name: rotateOutDownLeft;
  }
  
  @keyframes rotateOutDownRight {
  }
  
  .rotateOutDownRight {
    -webkit-animation-name: rotateOutDownRight;
    animation-name: rotateOutDownRight;
  }
  
  @keyframes rotateOutUpLeft {
  }
  
  .rotateOutUpLeft {
    -webkit-animation-name: rotateOutUpLeft;
    animation-name: rotateOutUpLeft;
  }
  
  @keyframes rotateOutUpRight {
  }
  
  .rotateOutUpRight {
    -webkit-animation-name: rotateOutUpRight;
    animation-name: rotateOutUpRight;
  }
  
  @keyframes hinge {
  }
  
  .hinge {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-name: hinge;
    animation-name: hinge;
  }
  
  @keyframes jackInTheBox {
  }
  
  .jackInTheBox {
    -webkit-animation-name: jackInTheBox;
    animation-name: jackInTheBox;
  }
  
  @keyframes rollIn {
  }
  
  .rollIn {
    -webkit-animation-name: rollIn;
    animation-name: rollIn;
  }
  
  @keyframes rollOut {
  }
  
  .rollOut {
    -webkit-animation-name: rollOut;
    animation-name: rollOut;
  }
  
  @keyframes zoomIn {
  }
  
  .zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
  }
  
  @keyframes zoomInDown {
  }
  
  .zoomInDown {
    -webkit-animation-name: zoomInDown;
    animation-name: zoomInDown;
  }
  
  @keyframes zoomInLeft {
  }
  
  .zoomInLeft {
    -webkit-animation-name: zoomInLeft;
    animation-name: zoomInLeft;
  }
  
  @keyframes zoomInRight {
  }
  
  .zoomInRight {
    -webkit-animation-name: zoomInRight;
    animation-name: zoomInRight;
  }
  
  @keyframes zoomInUp {
  }
  
  .zoomInUp {
    -webkit-animation-name: zoomInUp;
    animation-name: zoomInUp;
  }
  
  @keyframes zoomOut {
  }
  
  .zoomOut {
    -webkit-animation-name: zoomOut;
    animation-name: zoomOut;
  }
  
  @keyframes zoomOutDown {
  }
  
  .zoomOutDown {
    -webkit-animation-name: zoomOutDown;
    animation-name: zoomOutDown;
  }
  
  @keyframes zoomOutLeft {
  }
  
  .zoomOutLeft {
    -webkit-animation-name: zoomOutLeft;
    animation-name: zoomOutLeft;
  }
  
  @keyframes zoomOutRight {
  }
  
  .zoomOutRight {
    -webkit-animation-name: zoomOutRight;
    animation-name: zoomOutRight;
  }
  
  @keyframes zoomOutUp {
  }
  
  .zoomOutUp {
    -webkit-animation-name: zoomOutUp;
    animation-name: zoomOutUp;
  }
  
  @keyframes slideInDown {
  }
  
  .slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
  }
  
  @keyframes slideInLeft {
  }
  
  .slideInLeft {
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft;
  }
  
  @keyframes slideInRight {
  }
  
  .slideInRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
  }
  
  @keyframes slideInUp {
  }
  
  .slideInUp {
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp;
  }
  
  @keyframes slideOutDown {
  }
  
  .slideOutDown {
    -webkit-animation-name: slideOutDown;
    animation-name: slideOutDown;
  }
  
  @keyframes slideOutLeft {
  }
  
  .slideOutLeft {
    -webkit-animation-name: slideOutLeft;
    animation-name: slideOutLeft;
  }
  
  @keyframes slideOutRight {
  }
  
  .slideOutRight {
    -webkit-animation-name: slideOutRight;
    animation-name: slideOutRight;
  }
  
  @keyframes slideOutUp {
  }
  
  .slideOutUp {
    -webkit-animation-name: slideOutUp;
    animation-name: slideOutUp;
  }
  
  #fw_al_001 {
    overflow: hidden;
    max-height: 750px;
    position: relative;
    background: #000;
  }
  
  #fw_al_001:hover {
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }
  
  #fw_al_001:active {
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
  
  #fw_al_001 > .carousel-inner > .carousel-item > a > img, #fw_al_001 > .carousel-inner > .carousel-item > img {
    opacity: .45;
    margin: auto;
    width: 100%!important;
  }
  
  .ps_slide_y .carousel-inner, .ps_slide_y .carousel-item {
    height: 100%;
  }
  
  .ps_slide_y .carousel-item-next.carousel-item-left, .ps_slide_y .carousel-item-prev.carousel-item-right {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  
  .ps_slide_y .active.carousel-item-right, .ps_slide_y .carousel-item-next {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  
  .ps_slide_y .active.carousel-item-left, .ps_slide_y .carousel-item-prev {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  
  .ps_indicators_y .carousel-indicators {
    top: 0;
    left: 3%;
    width: 5%;
    bottom: 0;
    height: 25%;
    right: auto;
    margin: auto;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .ps_indicators_y .carousel-indicators li {
    width: 13px;
    height: 13px;
    display: block;
    margin: 10px auto;
    background: 0 0;
    border-radius: 0;
    -webkit-border-radius: 0;
    border: 2px solid rgba(76,133,75,0.7);
  }
  
  .ps_indicators_y .carousel-indicators .active {
    width: 13px;
    height: 13px;
    margin: 10px auto;
    background: #016090;
  }
  
  .ps_indicators_y .carousel-indicators::-webkit-scrollbar {
    width: 3px;
  }
  
  .ps_indicators_y .carousel-indicators::-webkit-scrollbar-thumb {
    background: rgba(255,255,255,.5);
    -webkit-border-radius: 0;
    border-radius: 0;
  }
  
  .fw_al_001_slide {
    left: 0;
    top: 25%;
    right: 0;
    width: 65%;
    margin: auto;
    position: absolute;
    text-align: center;
  }
  
  .fw_al_001_slide > h3 {
    color: #fff;
    font-size: 20px;
    margin-top: 20px;
    font-weight: 400;
    letter-spacing: 6px;
    line-height: normal;
    text-transform: uppercase;
    font-family: Allura;
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
    -webkit-animation-duration: 6s;
    animation-duration: 6s;
    -webkit-animation-timing-function: cubic-bezier(.23,1,.32,1);
    animation-timing-function: cubic-bezier(.23,1,.32,1);
  }
  
  .fw_al_001_slide > h1, .fw_al_001_slide > p {
    color: #ffffff;
    line-height: normal;
    -webkit-animation-delay: .5s;
    -webkit-animation-timing-function: cubic-bezier(.23,1,.32,1);
    font-weight: 400;
  }
  
  .fw_al_001_slide > h1 {
    font-size: 60px;
    font-weight: 500;
    font-style: italic;
    margin-top: 20px;
    margin-bottom: 30px;
    text-transform: capitalize;
    font-family: cinzel;
    animation-delay: .5s;
    -webkit-animation-duration: 7s;
    animation-duration: 7s;
    animation-timing-function: cubic-bezier(.23,1,.32,1);
  }
  
  .fw_al_001_slide > p {
    font-size: 17px;
    letter-spacing: 1px;
    margin-bottom: 10px;
    font-family: Roboto;
    animation-delay: .5s;
    -webkit-animation-duration: 8s;
    animation-duration: 8s;
    animation-timing-function: cubic-bezier(.23,1,.32,1);
  }
  
  .fw_al_001_slide > a, .fw_al_001_slide > a:visited {
    outline: 0;
    width: auto;
    height: 50px;
    color: #ffffff;
    padding: 0 23px;
    cursor: pointer;
    font-size: 13px;
    text-align: left;
    text-shadow: none;
    line-height: 50px;
    margin: 30px 10px;
    position: relative;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    letter-spacing: 1px;
    display: inline-block;
    text-decoration: none;
    border: 2px solid #016090;
    text-transform: uppercase;
    font-family: cinzel;
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
    -webkit-animation-duration: 9s;
    animation-duration: 9s;
    -webkit-animation-timing-function: cubic-bezier(.23,1,.32,1);
    animation-timing-function: cubic-bezier(.23,1,.32,1);
    -webkit-transition: background cubic-bezier(.23,1,.32,1) .7s;
    transition: background cubic-bezier(.23,1,.32,1) .7s;
  }
  
  .fw_al_001_slide > a:active, .fw_al_001_slide > a:hover {
    color: #fff;
    background: rgba(23,52,23,0.63);
    border: 2px solid #58993a;
  }
  
  @media only screen and (max-width:480px) {
    .fw_al_001_slide {
      top: 0;
      width: 100%;
    }
  }
  
  @media only screen and (max-width:480px) {
    .ps_indicators_y > .carousel-indicators {
      height: 100%;
    }
  }
  
  @media only screen and (max-width:480px) {
    .fw_al_001_slide > h3 {
      font-size: 16px;
    }
  }
  
  @media only screen and (max-width:480px) {
    .fw_al_001_slide > h1 {
      font-size: 30px;
      margin-top: 10px;
      margin-bottom: 5px;
    }
  }
  
  @media only screen and (max-width:480px) {
    .fw_al_001_slide > p {
      font-size: 14px;
    }
  }
  
  @media only screen and (max-width:480px) {
    .fw_al_001_slide > a, .fw_al_001_slide > a:visited {
      height: 40px;
      margin: 0 10px;
      padding: 0 23px;
      line-height: 40px;
    }
  }
  
  @media (min-width:481px) and (max-width:600px) {
    .fw_al_001_slide {
      top: 5%;
      width: 100%;
    }
  }
  
  @media (min-width:481px) and (max-width:600px) {
    .fw_al_001_slide > h1 {
      font-size: 40px;
    }
  }
  
  @media (min-width:601px) and (max-width:767px) {
    .fw_al_001_slide {
      top: 15%;
      width: 100%;
    }
  }
  
  .ps_ease .carousel-item {
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
  }
  
  .ps_easeInOut .carousel-item {
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
  }
  
  .ps_speedy .carousel-item {
    -webkit-transition-timing-function: cubic-bezier(0,1,0,1);
    transition-timing-function: cubic-bezier(0,1,0,1);
  }
  
  .ps_slowSpeedy .carousel-item {
    -webkit-transition-timing-function: cubic-bezier(.3,1,0,1);
    transition-timing-function: cubic-bezier(.3,1,0,1);
  }
  
  .ps_easeOutInCubic .carousel-item {
    -webkit-transition-timing-function: cubic-bezier(.22,.81,.01,.99);
    transition-timing-function: cubic-bezier(.22,.81,.01,.99);
  }
  
  .ps_swing .carousel-item {
    -webkit-transition-timing-function: cubic-bezier(.02,.01,.47,1);
    transition-timing-function: cubic-bezier(.02,.01,.47,1);
  }
  
  .ps_easeOutCubic .carousel-item {
    -webkit-transition-timing-function: cubic-bezier(.215,.61,.355,1);
    transition-timing-function: cubic-bezier(.215,.61,.355,1);
  }
  
  .ps_easeInOutCubic .carousel-item {
    -webkit-transition-timing-function: cubic-bezier(.645,.045,.355,1);
    transition-timing-function: cubic-bezier(.645,.045,.355,1);
  }
  
  .ps_easeOutCirc .carousel-item {
    -webkit-transition-timing-function: cubic-bezier(.075,.82,.165,1);
    transition-timing-function: cubic-bezier(.075,.82,.165,1);
  }
  
  .ps_easeOutExpo .carousel-item {
    -webkit-transition-timing-function: cubic-bezier(.19,1,.22,1);
    transition-timing-function: cubic-bezier(.19,1,.22,1);
  }
  
  .ps_easeInQuad .carousel-item {
    -webkit-transition-timing-function: cubic-bezier(.55,.085,.68,.53);
    transition-timing-function: cubic-bezier(.55,.085,.68,.53);
  }
  
  .ps_easeOutQuad .carousel-item {
    -webkit-transition-timing-function: cubic-bezier(.25,.46,.45,.94);
    transition-timing-function: cubic-bezier(.25,.46,.45,.94);
  }
  
  .ps_easeInOutQuad .carousel-item {
    -webkit-transition-timing-function: cubic-bezier(.455,.03,.515,.955);
    transition-timing-function: cubic-bezier(.455,.03,.515,.955);
  }
  
  .ps_easeOutQuart .carousel-item {
    -webkit-transition-timing-function: cubic-bezier(.165,.84,.44,1);
    transition-timing-function: cubic-bezier(.165,.84,.44,1);
  }
  
  .ps_easeInOutQuart .carousel-item {
    -webkit-transition-timing-function: cubic-bezier(.77,0,.175,1);
    transition-timing-function: cubic-bezier(.77,0,.175,1);
  }
  
  .ps_easeOutQuint .carousel-item {
    -webkit-transition-timing-function: cubic-bezier(.23,1,.32,1);
    transition-timing-function: cubic-bezier(.23,1,.32,1);
  }
  
  .ps_easeInSine .carousel-item {
    -webkit-transition-timing-function: cubic-bezier(.47,0,.745,.715);
    transition-timing-function: cubic-bezier(.47,0,.745,.715);
  }
  
  .ps_easeOutSine .carousel-item {
    -webkit-transition-timing-function: cubic-bezier(.39,.575,.565,1);
    transition-timing-function: cubic-bezier(.39,.575,.565,1);
  }
  
  .ps_easeInOutSine .carousel-item {
    -webkit-transition-timing-function: cubic-bezier(.445,.05,.55,.95);
    transition-timing-function: cubic-bezier(.445,.05,.55,.95);
  }
  
  

    /* ===================Slider ====================*/

    