.navigation-clean-button {
  background: #222222;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #f2f5f8;
  border-radius: 0;
  box-shadow: none;
  border: none;
  margin-bottom: 0;
}

@media (max-width:767px) {
  .navigation-clean-button {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (max-width:767px) {
  .navigation-clean-button .navbar-header {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (max-width:767px) {
  .navigation-clean-button .navbar-right {
    margin-bottom: 20px;
  }
}

.navigation-clean-button .navbar-brand {
  font-weight: bold;
  color: inherit;
}

.navigation-clean-button .navbar-brand:hover {
  color: #1485ee;
}

.navigation-clean-button .navbar-brand img {
  height: 100%;
  display: inline-block;
  margin-right: 10px;
  width: auto;
}

.navigation-clean-button .navbar-toggle {
  border-color: #ddd;
}

.navigation-clean-button .navbar-toggle:hover, .navigation-clean-button .navbar-toggle:focus {
  background: none;
}

.navigation-clean-button .navbar-toggle .icon-bar {
  background-color: #888;
}

.navigation-clean-button .navbar-collapse, .navigation-clean-button .navbar-form {
  border-top-color: #ddd;
}

.navigation-clean-button .navbar-nav>.active>a, .navigation-clean-button .navbar-nav>.open>a {
  background: none;
  box-shadow: none;
}

.navigation-clean-button.navbar-default .navbar-nav>.active>a, .navigation-clean-button.navbar-default .navbar-nav>.active>a:focus, .navigation-clean-button.navbar-default .navbar-nav>.active>a:hover {
  color: #e04c40;
  border-top: 1px solid #e04c40;
  box-shadow: none;
  transition: background-color 0.25s;
  background: none;
  pointer-events: none;
}

.navigation-clean-button.navbar .navbar-nav>li>a {
  padding-left: 18px;
  padding-right: 18px;
}

.navigation-clean-button.navbar-default .navbar-nav>li>a {
  color: #dfe8ee;
  font-weight: 400;
  text-transform: uppercase;
  font-family: 'Work Sans';
}

.navigation-clean-button.navbar-default .navbar-nav>li>a:focus, .navigation-clean-button.navbar-default .navbar-nav>li>a:hover {
  color: #e04c40 !important;
  border-top: solid 1px #e04c40;
  background-color: transparent;
  transition: background 0.2s ease, padding 0.8s linear;
  transition: background-color 0.25s;
}

.navigation-clean-button .navbar-nav>li>.dropdown-menu {
  margin-top: -5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
  background-color: #222222;
  border-radius: 2px;
}

.navigation-clean-button .dropdown-menu>li>a:focus, .navigation-clean-button .dropdown-menu>li>a {
  line-height: 2;
  font-size: 14px;
  font-weight: 300;
  font-family: 'Roboto';
  color: #df4c40;
}

.navigation-clean-button .dropdown-menu>li>a:focus, .navigation-clean-button .dropdown-menu>li>a:hover {
  background: #df4c40;
  color: inherit;
  transition: background-color 0.25s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

.navigation-clean-button .actions {
  margin-top: 7px;
  margin-bottom: 0;
}

.navigation-clean-button .actions .login {
  margin-right: 7px;
  text-decoration: none;
  color: #ffffff;
}

.navigation-clean-button .action-button, .navigation-clean-button .action-button:active {
  background: transparent;
  border-radius: 0;
  color: #e04c40;
  box-shadow: none;
  border: 1px solid #e04c40;
  text-shadow: none;
  padding: 10px 22px;
  transition: background-color 0.25s;
}

.navigation-clean-button .action-button:hover {
  background: #e04c40;
  color: #fff;
  font-weight: 400;
  border: 1px solid #df4c40;
}

.navbar {
  margin-bottom: 0;
}

.btn {
  border-radius: 0;
}

a.navbar-brand.navbar-link {
  font-weight: 400;
}

p.navbar-text.navbar-right.actions {
  font-family: 'Roboto Slab';
  text-transform: uppercase;
  font-weight: 400;
}

a.navbar-link.login:hover {
  color: #e04c40;
}

.btn.focus, .btn:focus, .btn:hover {
  color: #ffffff;
  text-decoration: none;
  background-color: #1485ee;
  border: none;
}

.btn-default {
  color: #ffffff;
  background-color: #df4c40;
  border: none;
}

.btn {
  font-family: 'Roboto';
  font-weight: 400;
}


/* ------------Carousel section -----------------------*/

div.jumbotron.carousel-hero {
  color: #fff;
  text-align: center;
  background-size: cover;
  background-position: center;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-bottom: 0;
}

div.jumbotron.hero-nature {
  background-image: url('../img/architect-at-the-construction.jpg');
}

div.jumbotron.hero-photography {
  background-image: url('../img/architects-and-worker.jpg');
}

div.jumbotron.hero-technology {
  background-image: url('../img/young-college-students.jpg');
}

h1.hero-title, h2.hero-title, h3.hero-title {
  text-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
  color: #fff;
}

p.hero-subtitle {
  max-width: 600px;
  margin: 25px auto;
  padding: 10px 15px;
  color: #fff;
}

.plat {
  border-radius: 0px;
}

.btn-primary.focus, .btn-primary:focus {
  box-shadow: 0 0 0 0 #048f83 !important;
}

.btn-primary:hover {
  color: #fff;
  background-color: #016090;
  border-color: #016090;
}

.btn.btn-primary {
  background-color: #048f83 !important;
  border-color: #048f83 !important;
}

.btn-primary.focus, .btn-primary:focus {
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5);
}


span {
  font-style: inherit;
  font-weight: inherit;
}

.homeComponent {
  color: #222222 !important;
}

/*-----------------Box section --- Services*/

.features-boxed {
  color: #313437;
  background-color: #ffffff;
}

.features-boxed p {
  color: #7d8285;
}

.features-boxed h2 {
  font-weight: bold !important;
  margin-bottom: 40px;
  padding-top: 40px;
  color: inherit;
}

@media (max-width:767px) {
  .features-boxed h2 {
    margin-bottom: 25px;
    padding-top: 25px;
    font-size: 24px;
  }
}

.features-boxed .intro {
  font-size: 16px;
  max-width: 500px;
  margin: 0 auto;
}

.features-boxed .intro p {
  margin-bottom: 0;
}

.features-boxed .features {
  padding: 50px 0;
}

.features-boxed .item {
  text-align: center;
}

.features-boxed .item .box {
  text-align: center;
  padding: 30px;
  background-color: #075305f5;
  margin-bottom: 30px;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  border-radius: 40px;
}

.features-boxed .item .icon {
  font-size: 60px;
  color: #4a5aa7;
  margin-top: 20px;
  margin-bottom: 35px;
}

.features-boxed .item .name {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
  margin-top: 0;
  color: inherit;
}

.features-boxed .item .description {
  font-size: 15px;
  margin-top: 15px;
  margin-bottom: 20px;
}

/*Imag side by side and text */

.clearmargin {
  margin: 0px;
}

.clearpadding {
  padding: 0px;
}

.row-image-txt {
  background-color: rgb(42, 42, 42);
}

.row-image-txt h1 {
  color: white;
}

.row-image-txt p {
  color: white;
}

.features-boxed h3 {
  color: #fff !important;
}

/*---------Main Footer-----------*/

#myFooter {
  background-color: #3c3d41;
  color: white;
  padding-top: 30px;
  /* position:absolute; */

}

#myFooter .footer-copyright {
  background-color: #333333;
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
}

#myFooter .footer-copyright p {
  margin: 10px;
  color: #ccc;
}

#myFooter ul {
  list-style-type: none;
  padding-left: 0;
  line-height: 1.7;
}

#myFooter h5 {
  font-size: 18px;
  color: white;
  font-weight: bold;
  margin-top: 30px;
}

#myFooter a {
  color: #d2d1d1;
  text-decoration: none;
}

#myFooter a:hover, #myFooter a:focus {
  text-decoration: none;
  color: white;
}

#myFooter .social-networks {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 16px;
}

#myFooter .social-networks a {
  font-size: 32px;
  color: #f9f9f9;
  padding: 10px;
  transition: 0.2s;
}

#myFooter .social-networks a:hover {
  text-decoration: none;
}

#myFooter .facebook:hover {
  color: #04457e;
}

#myFooter .linkedin:hover {
  color: #3093e9;
}

#myFooter .envelope:hover {
  color: #fa8a01;
}

#myFooter .whatsapp:hover {
  color: #1ff130;
}

#myFooter .google:hover {
  color: #ef1a1a;
}

#myFooter .twitter:hover {
  color: #00aced;
}


#myFooter .btn {
  color: white;
  background-color: #d84b6b;
  border-radius: 20px;
  border: none;
  width: 150px;
  display: block;
  margin: 0 auto;
  margin-top: 10px;
  line-height: 25px;
}

@media screen and (max-width: 767px) {
  #myFooter {
    text-align: center;
  }
}



/*================= image scroll us section=================*/

#content-block {
  position: relative;
  overflow: hidden;
}

.parallax-slide {
  position: relative;
  height: 100vh;
}

.parallax-clip {
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
  /*clip: rect(auto, auto, auto, auto);*/
}

.fixed-parallax {
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: center top;
  background-attachment: fixed;
  /* -webkit-transform: translateZ(0); /* necessary for iOS! */
}


/* used only as overlay for the images,
     darkens it a little in this case */
.fixed-parallax:before {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  content: "";
}

/* =============================animated text====================== */


/* ===========================About page ===================================*/


.about-section {
  background: linear-gradient(1deg, rgba(239, 239, 239, 0.4) 26%, rgb(245, 243, 255) 73%);
  padding: 70px 0;
}

.about-image-box {
  position: relative;
  flex: 1;
  max-width: 100%;
}

.shape-1 {
  width: 531px;
  height: 384px;
  background-image: none;
  margin: 264px auto 0 72px;
  color: #ffffff;
  background-color: #6c5ce7;
}

.shape-2 {
  width: 447px;
  height: 382px;
  background-image: none;
  margin: -603px auto 0 0;
  border-color: #6c5ce7;
  stroke: #6c5ce7;
  border-style: solid;
  border-width: 16px;
}

.about-inner-image-box {
  width: 393px;
  min-height: 248px;
  /* background-image: url(../img/ctech.jpeg); */
  background-position: 50% 50%;
  margin: 39px 0 0 auto;
}

.about-image-box img {
  border-width: 0;
  object-fit: cover;
  display: block;
  /* vertical-align: middle; */
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 564px;
  height: 526px;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.5);
  object-position: 28.7% 100%;
  background-position: 28.7% 100%;
  margin: -713px 0 0 auto;
  margin: 0 auto;
}

.layout-33 {
  position: relative;
  flex: 1;
  max-width: 100%;
}

.about_img {
  border: 21px solid transparent;
  text-align: left;
  background-color: transparent !important;
  opacity: 1;
  position: relative;
  display: flex;
  background-clip: padding-box;
  background-origin: padding-box;
}

.section-title {
  color: #4a5aa7;
  text-transform: capitalize;
  font-weight: bold;
  letter-spacing: 0.7px;
  font-size: 3.75rem;
  line-height: 50px;
  padding-bottom: 10px;
  font-family: 'Oswald', sans-serif;
}

.about-text p {
  line-height: 30px;
  margin-bottom: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  color: #000 !important;
}

.about_img:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.p-0 {
  padding: 0;
}

a.custom-btn-nav {
  border: 2px solid #6c5ce7;
  color: #000;
  width: 150px;
  display: inline-block;
  text-align: center;
  padding: 12px;
  box-shadow: 1px 2px 0px 0px #6b5be6;
}

.about-text {
  padding: 180px 0;
}

/*Media query for mobile view  */

@media only screen and (max-width: 767px) {
  .about-text {
    padding: 15px;
  }

  .p-0 {
    padding: 15px;
  }

  .about-image-box img {
    width: 268px;
    height: 332px;
    margin-top: -463px;
    margin-right: 0;
    margin-bottom: 0;
  }

  .about-inner-image-box {
    width: 212px;
    min-height: 264px;
    margin-top: -82px;
  }

  .shape-1 {
    width: 255px;
    height: 201px;
    margin-top: 210px;
    margin-right: 40px;
    margin-left: auto;
  }

  .shape-2 {
    height: 249px;
    width: 261px;
    margin-top: -378px;
    margin-right: auto;
    margin-left: 0;
  }

  .about_img {
    min-height: 533px;
  }
}

/* Vertical Tabs */

.py-5 {
  background-color: #4a5aa7;
  color: #fff;
  margin: 0 auto;

}

.nav-pills-custom .nav-link {
  color: #aaa;
  background: #fff;
  position: relative;
}

.nav-pills-custom .nav-link.active {
  color: #45b649;
  background: #fff;
}


/* Add indicator arrow for the active tab */
@media (min-width: 992px) {
  .nav-pills-custom .nav-link::before {
    content: '';
    display: block;
    border-top: 8px solid transparent;
    border-left: 10px solid #fff;
    border-bottom: 8px solid transparent;
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    opacity: 0;
  }
}

.nav-pills-custom .nav-link.active::before {
  opacity: 1;
}

/*=========================About page closed ==============================*/