.features-blue {
    color: #fff;
    background: linear-gradient(135deg, #6c5ce7, #6c5ce75d);
    background-color: #6c5ce7;
    margin-bottom: 80px;
    /*padding-bottom: 30px;*/
  }
  
  @media (max-width:767px) {
    .features-blue {
      padding-bottom: 10px;
    }
  }
  
  .features-blue p {
    color: rgba(255,255,255,0.6);
  }
  
  .features-blue h2 {
    font-weight: bold;
    margin-bottom: 40px;
    padding-top: 40px;
    color: inherit;
  }
  
  @media (max-width:767px) {
    .features-blue h2 {
      margin-bottom: 25px;
      padding-top: 25px;
      font-size: 24px;
    }
  }
  
  .features-blue .intro {
    font-size: 16px;
    max-width: 500px;
    margin: 0 auto 60px;
  }
  
  @media (max-width:767px) {
    .features-blue .intro {
      margin-bottom: 40px;
    }
  }
  
  .features-blue .item {
    min-height: 100px;
    padding-left: 80px;
    margin-bottom: 40px;
  }
  
  @media (max-width:767px) {
    .features-blue .item {
      min-height: 0;
    }
  }
  
  .features-blue .item .name {
    font-size: 20px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 20px;
    color: inherit;
  }
  
  .features-blue .item .icon {
    font-size: 40px;
    color: #ffcc1e;
    float: left;
    /*margin-left: -65px;*/
  }
  
  @media (max-width: 575px) {
    .mobilecentericon {
      text-align: center;
      margin-left: 0px;
    }
  }
  
  @media (max-width: 575px) {
    .mobilecentertext {
      text-align: center;
    }
  }
  
  @media (min-width: 576px) {
    .mobilecentertext {
      text-align: left;
    }
  }
  
  @media (min-width: 576px) {
    .mobilecentericon {
      margin-left: -65px;
    }
  }
  
  /* The main image and text row - ICT */

  .two-columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
}

.column {
    flex: 1;
    margin: 15px;
}

.column img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.text-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.text-column h2 {
    color: #6c5ce7;
}

.text-column p {
    color: #495057;
}

.text-column a.btn {
    margin-top: 10px;
}

.techmaim{
    margin-top: 80px;
    margin-bottom: 80px;
}