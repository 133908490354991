/* Main header section */

.main-text a:hover {
    background: rgba(255, 255, 255, 0.568);
    border-radius: 3px;
    color: #fff;
  }
  #main {
    width: 100%;
    height: 100vh;
    position: relative;
    /* background: linear-gradient(to right, rgba(34, 35, 46, 0.75), rgba(0, 194, 203, 0.5)); */
  }
  
  .main-text {
    position: absolute;
    left: 13%;
    top: 42%;
    transform: translate(-13%, -42%);
    line-height: 0;
    background: linear-gradient(to right, rgba(34, 35, 46, 0.75), #4a59a780);
    padding: 30px;
    border-radius: 10px;
  }
  
  .main-text h2{
    font-weight: 500;
    font-size: 30px;
    color: #fff;
  }
  .main-text h1{
    font-size: 70px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    color: #e0ffff;
  }
  .main-text p{
    font-size: 20px;
    color: #fff;
    margin: 25px 0px 25px;
    line-height: 50px;
  }
  .main-text a{
    width: 150px;
    height: 40px;
    outline: none;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 200;
    padding: 1rem;
  }
  .btn{
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: #e0ffff;
    font-family: "Asap", sans-serif;
  }
  #main video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  
/* ============================================================================ */
  /* UI card section of agribusiness page */

/* =========================================================================== */
.agrintro{
    margin-top: 40px;
    margin-bottom: 40px;
}
h1 {
    text-align: center;
    margin-bottom: 50px;
    margin-top: 50px;
  }
  
  .blog-card-blog {
    margin-top: 30px;
  }
  
  .blog-card {
    display: inline-block;
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    border-radius: 6px;
    color: rgba(0, 0, 0, 0.87);
    background: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  
  .blog-card .blog-card-image {
    height: 60%;
    position: relative;
    overflow: hidden;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: -30px;
    border-radius: 6px;
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  }
  
  .blog-card .blog-card-image img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    pointer-events: none;
  }
  
  .blog-card .blog-table {
    padding: 15px 30px;
  }
  
  .blog-table {
    margin-bottom: 0px;
  }
  
  .blog-category {
    position: relative;
    line-height: 0;
    margin: 15px 0;
  }
  
  .blog-text-success {
    color: #4d4d4d!important;
  }
  
  .blog-card-blog .blog-card-caption {
    margin-top: 5px;
  }
  
  .blog-card-caption {
    font-weight: 700;
    font-family: "Roboto Slab", "Times New Roman", serif;
  }
  
  .blog-card-caption, .blog-card-caption a {
    color: #333;
    text-decoration: none;
  }
  
  p {
    color: #3C4857;
    margin-top: 0;
    margin-bottom: 1rem;
  }
  
  .blog-card .ftr {
    margin-top: 15px;
  }
  
  .blog-card .ftr .author {
    color: #888;
  }
  
  .blog-card .ftr div {
    display: inline-block;
  }
  
  .blog-card .author .avatar {
    width: 36px;
    height: 36px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  .blog-card .ftr .stats {
    position: relative;
    top: 1px;
    font-size: 14px;
  }
  
  .blog-card .ftr .stats {
    float: right;
    line-height: 30px;
  }
  
  a {
    text-decoration: none;
  }
  
  
  /* /* =========================// animated text reveal========================== */


  .bigger {
    margin: 0;
    font-size: 60px;
    font-weight: 800;
    padding: 20px;
    text-transform: uppercase;
    color: #202020;
    display: inline-block;
    position: relative;
  }
  
  .text {
    max-width: 600px;
    width: 100%;
    line-height: 24px;
    text-align: left;
    color: #404040;
    padding: 20px;
  }
  .text.txt-center {
    text-align: center;
  }
  .text a {
    color: #0fe4d2;
  }
  
  .has-animation {
    position: relative;
  }
  .has-animation p, .has-animation img {
    opacity: 0;
  }
  .has-animation.animate-in p, .has-animation.animate-in img {
    animation: textHidden 0.1s 1.1s forwards;
  }
  .has-animation.animate-in:before, .has-animation.animate-in:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 10;
  }
  .has-animation.animate-in:before {
    background-color: teal;
  }
  .has-animation.animate-in:after {
    background-color: yellowgreen;
    animation-delay: .5s;
  }
  .has-animation.animation-ltr.animate-in:before {
    animation: revealLTR 1.8s ease;
  }
  .has-animation.animation-ltr.animate-in:after {
    animation: revealLTR 1s .6s ease;
  }
  .has-animation.animation-rtl.animate-in:before {
    animation: revealRTL 1.8s ease;
  }
  .has-animation.animation-rtl.animate-in:after {
    animation: revealRTL 1s .6s ease;
  }
  
  @keyframes revealRTL {
    0% {
      width: 0;
      right: 0;
    }
    65% {
      width: 100%;
      right: 0;
    }
    100% {
      width: 0;
      right: 100%;
    }
  }
  @keyframes revealLTR {
    0% {
      width: 0;
      left: 0;
    }
    65% {
      width: 100%;
      left: 0;
    }
    100% {
      width: 0;
      left: 100%;
    }
  }
  @keyframes textHidden {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  